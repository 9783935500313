<template>
    <div class="success-page">
        <main class="main-content">
            <div class="page-title">
                <div v-if="msg"> {{msg}} </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    props: ['msg'],
    components: {
    }
}
</script>
<style lang="less">
.success-page {
    .page-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
            0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        height: 35px;
    }
}
</style>

